@font-face {
  font-family: "Mont";
  src: url("./Mont-Regular.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Mont";
  src: url("./Mont-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Mont";
  src: url("./Mont-Book.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Mont";
  src: url("./Mont-BookItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Mont";
  src: url("./Mont-Bold.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Mont";
  src: url("./Mont-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
